<template>
  <v-container
    tag="section"
    fluid
  >
    <v-form @submit.prevent="upload">
      <v-row>
        <v-col
          cols="12"
          md="12"
          sm="12"
        >
          <v-file-input
            v-model="file"
            :prepend-icon="null"
            prepend-inner-icon="mdi-file-delimited-outline"
            accept="text/csv, application/zip"
            placeholder="Fichier csv"
            label="Fichier csv"
            outlined
            hide-details
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col
          md="6"
          align="right"
        >
          <v-btn
            type="submit"
            color="primary"
          >
            {{ $t('forms.buttons.upload') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import taskMixin from '@/mixins/task'

export default {
  name: 'ExternalCatalogSyncFromCSV',
  mixins: [taskMixin],
  props: {
    provider: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      file: null,
    }
  },
  methods: {
    async upload() {
      if (this.file === null) {
        return
      }

      const formData = new FormData()
      formData.append('provider', this.provider)
      formData.append('file', this.file)

      try {
        const response = await this.$axios.post(
          'external_catalog/synchro_tasks/from_csv',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )

        this.taskCreated(response.data.id)
      } catch (error) {
        this.taskFailed(error.response.data['hydra:description'] ?? error.response.data['detail'] ?? null)
      } finally {
        this.file = null
      }
    },
  },
}
</script>
